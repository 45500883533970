<template>
  <div>
    <section class="section-about">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-6" style="margin-bottom: 20px">
            <BlockTitle emName="about us" zhName="关于我们"></BlockTitle>
            <div class="about-desc">
              ⾄信微电⼦由国内知名功率半导体专家张爱忠先⽣创⽴，团队汇聚了来⾃华润微、意法半导体等顶尖半导体企业的资深精英，拥有雄厚的产业资源和深厚的⾏业背景。<br /><br />
              公司致⼒于开发顶级的第三代半导体功率器件，以其⾏业领先的设计与制造技术为基⽯，
              推出的碳化硅MOSFET产品不仅良率超过90%，技术性能卓越，更是在国内率先研发1200V/7mΩ、750V/5mΩ等⾏业领先的SiC芯⽚，在新能源汽⻋、光伏、⼯业等关键领域得到⼴泛应⽤和认可。
            </div>
            <div class="row">
              <div class="col-xs-6 col-md-6">
                <span class="font28 bold">第三代</span><br />
                半导体功率器件
              </div>
              <div class="col-xs-6 col-md-6">
                <span class="font28 bold">90%-98%</span><br />
                碳化硅MOSFET产品良率
              </div>
              <div class="col-xs-6 col-md-6">
                <span class="font28 bold">国内领先</span><br />
                ⾏业领先的SiC芯⽚
              </div>
              <div class="col-xs-6 col-md-6">
                <span class="font28 bold">应用广泛</span><br />
                多行业⼴泛应⽤和认可
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-1"></div>
          <div class="col-xs-12 col-md-5">
            <img
              src="http://img.sicred.cn/assets/img/banner/about.jpg"
              class="product-img-in"
              style="width: 100%"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="section-about bgFa" style="display:none;">
      <!-- 技术路线 -->

      <div>
        <BlockTitle emName="TECHNICAL ROADMAP" zhName="技术路线"></BlockTitle>
        <div class="container">
            <el-image
            src="http://img.sicred.cn/assets/img/product/timeline.png"
            class="technologyImg"
            :preview-src-list="['http://img.sicred.cn/assets/img/product/timeline.png']"
          />
        </div>
      </div>
    </section>

    <section class="section-about por">
      <!-- 产品资质 -->
      <img
        class="productQualification"
        src="http://img.sicred.cn/assets/img/product/productQualification.png"
        alt=""
      />

        <BlockTitle
        emName="PRODUCT QUALIFICATION"
        zhName="产品资质"
        ></BlockTitle>
        <div class="container">
        <div class="col-md-12 btnList">
          <div
            class="btn1"
            @click="handleCarousel(1)"
            :class="{ active: active == 1 }"
          >
            <span class="lique">01</span> 质量保证
          </div>
          <div
            class="btn1"
            @click="handleCarousel(2)"
            :class="{ active: active == 2 }"
          >
            <span class="lique">02</span> 可靠性保证
          </div>
        </div>
        <ul class="col-md-12 productQualification-text" v-if="active == 1">
          <li>
            公司已获得ISO9001:2015质量管理体系认证，持续遵循ISO9001:2015质量管理体系标准要求运行管理，以持续、稳定地设计出符合产品质量标准,
            满足顾客要求的产品；
          </li>
          <li>
            选择符合资质的合格供应商合作，确保从芯片流片生产，到封装测试过程，均能持续、稳定地输出符合产品质量标准，满足顾客要求的产品；合作供应商已获得ISO9001:2015、
            IATF16949:2016、 ISO14001:2015、
            ISO45001:2018、QC080000:2017多体系认证
          </li>
        </ul>
        <ul class="col-md-12 productQualification-text" v-if="active == 2">
          <li>
            SMC80N120T4AA 已过AEQ-101车规认证
          </li>
          <li>
            1200V、650V系列MOSFET已通过HTRB（175℃/100%BV）1000小时、HVH3TRB（高压） 1000小时等多项可靠性考核
          </li>
        </ul>
        <!-- 轮播图 -->

        <div class="col-md-12 bigCarousel">
          <el-carousel
            trigger="click"
            height="500px"
            arrow="always"
            indicator-position="outside"
          >
            <el-carousel-item alt="">
              <img :src="currentCertificateList[0]" alt="" class="carousePic" />
              <img :src="currentCertificateList[1]" alt="" class="carousePic" /> <img :src="currentCertificateList[2]" alt="" class="carousePic" />
            </el-carousel-item>
            <el-carousel-item alt="">
             
              <img :src="currentCertificateList[3]" alt="" class="carousePic" /> <img :src="currentCertificateList[4]" alt="" class="carousePic" />
              <img :src="currentCertificateList[5]" alt="" class="carousePic" />
            </el-carousel-item>
          
          </el-carousel>
        </div>

        <div class="smallCarousel">
          <el-carousel
            trigger="click"
            height="500px"
            arrow="always"
            indicator-position="outside"
          >
            <el-carousel-item alt="">
              <img :src="currentCertificateList[0]" alt="" class="carousePic" />
            </el-carousel-item>
            <el-carousel-item alt="">
              <img :src="currentCertificateList[1]" alt="" class="carousePic" />
            </el-carousel-item>
            <el-carousel-item alt="">
              <img :src="currentCertificateList[2]" alt="" class="carousePic" />
            </el-carousel-item>
          
            <el-carousel-item alt="">
              <img :src="currentCertificateList[3]" alt="" class="carousePic" />
            </el-carousel-item>
          
            <el-carousel-item alt="">
              <img :src="currentCertificateList[4]" alt="" class="carousePic" />
            </el-carousel-item>
          
            <el-carousel-item alt="">
              <img :src="currentCertificateList[5]" alt="" class="carousePic" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BlockTitle from "../BlockTitle";
export default {
  name: "HelloWorld",
  components: {
    BlockTitle,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      active: 1,
      // 当前资质数据
      currentCertificateList: [
        require("../../assets/img/certificate/certificate1-01.png"),
        require("../../assets/img/certificate/certificate1-02.png"),
        require("../../assets/img/certificate/certificate1-03.png"),
        require("../../assets/img/certificate/certificate1-04.png"),
        require("../../assets/img/certificate/certificate1-05.png"),
        require("../../assets/img/certificate/certificate1-06.png"),
      ],

      // 当前轮播图页面
      currentCarousePage: {
        1: 1,
        2: 1,
      },

      // 所有轮播图数据 1质量保证  2可靠性保证
      carouselList: {
        1: [
          require("../../assets/img/certificate/certificate1-01.png"),
          require("../../assets/img/certificate/certificate1-02.png"),
          require("../../assets/img/certificate/certificate1-03.png"),
          require("../../assets/img/certificate/certificate1-04.png"),
          require("../../assets/img/certificate/certificate1-05.png"),
          require("../../assets/img/certificate/certificate1-06.png"),
        ],
        2: [
          require("../../assets/img/certificate/certificate2-01.png"),
          require("../../assets/img/certificate/certificate2-02.png"),
          require("../../assets/img/certificate/certificate2-03.png"),
          require("../../assets/img/certificate/certificate2-04.png"),
          require("../../assets/img/certificate/certificate2-05.png"),
          require("../../assets/img/certificate/certificate2-06.png"),
        ],
      },
    };
  },
  methods: {
    handleCarousel(val) {
      this.active = val;
      this.$set(this, "currentCertificateList", this.carouselList[this.active]);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.w {
  width: 1920px;
  margin: 0 auto;
}
.about-wrapper {
  padding-right: 20px;
  padding-left: 20px;
}
.cimg {
  text-align: right;
}
.csize {
  text-align: left;
}

@media (max-width: 979px) {
  .cimg {
    text-align: center;
  }
  .csize {
    text-align: center;
  }
}

.section-about {
  padding: 45px 0;
  background: #fff;
}
@media (max-width: 768px) {
  .cimg {
    text-align: center;
  }
  .csize {
    text-align: center;
  }
  .section-about {
    padding: 15px 0;
    background: #fff;
  }
}
.about-desc {
  font-size: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  line-height: 32px;
  text-align: justify;
  margin-bottom: 64px;
}
.technologyImg {
  /* margin: 0 auto; */
  width: 100%;
}
.productQualification {
  width: 100%;
  position: absolute;
  top: 0;
}
.bgFa {
//   background-color: #fafafa;
}
.bgFe {
  background-color: #fefefe;
}
.por {
  position: relative;
}
.btnList {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  .btn1 {
    margin-right: 30px;
    width: 260px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    text-align: center;
    background: #002b5c;
    border-radius: 8px;
    cursor: pointer;
    opacity: 0.8;
  }
  .lique {
    font-style: oblique;
  }
  .active {
    opacity: 1;
  }
}
.productQualification-text {
  li {
    list-style-type: disc !important;
  }
}
.carousel {
  width: 100%;
  height: 372px;
  background-color: red;
  position: relative;
}
.carousePic {
  width: 262px;
  height: 372px;
  margin-right: 20px;
}
::v-deep .el-carousel__item {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-carousel__button {
  width: 60px;
  height: 6px;
  background: #002b5c;
}
.product-img-in {
  width: 100%;
}

.smallCarousel{
    display: none;
}
@media (max-width: 768px) {
    ::v-deep .el-carousel__button {
    width: 10px;
    height: 3px;
    background: #002b5c;
    }
    .smallCarousel{
        display: block;
    }
    .bigCarousel{
        display: none;
    }
    .productQualification {
        display: none;
    }
    .carousePic {
        margin-right: 0;
    }
}
</style>
