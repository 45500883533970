<template>
  <div>
    <Header  :tabActive="4"/>
    <ScrollSmall :pageSmallTile="pageSmallTile"  />
    <AboutContent />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header';
import ScrollSmall from '../components/ScrollSmall';
import AboutContent from '../components/about/About-content-new';
import Footer from '../components/Footer';

export default {
  name: 'App',
  components: {
    Header,
    ScrollSmall,
    AboutContent,
    Footer,
  },
  data () {
    return {
      pageSmallTile: {
        title: '关于我们',
        url: 'http://img.sicred.cn/assets/img/banner/aboutUs.png',
        tis: [
          {
            name:'ABOUT US',
            url:'#/about'
          }
        ]
      }
    }
  }

}
</script>

<style>
</style>
